<template>
  <div class="zhiwei">
    <div class="main">
      <img src="../../common/image/zhiweixiangqing (1).png" alt="" />
      <!-- 职位要求 -->
      <div class="job">
        <div class="top">
          <div class="title">{{ list.postName }} ({{ list.salary }}k)</div>
          <div>
            {{ list.workAddres }} | {{ list.workExperience }} |
            {{ list.education }}
            <!-- 招聘人数 -->
            <!-- | {{}} -->
          </div>
          <div>工作地点：{{ list.company }}</div>
        </div>
        <!-- 职位描述 -->
        <div class="detail" v-html="list.jobDetails">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "zhiwei",
  props: ["datalist"],
  data() {
    return {
      id: 0, // 职位详情id
      list: [], // 职位详情
    };
  },
  mounted() {
    this.id = this.$route.query.id; // 详情id
    this.getdetail(this.id); // 职位详情
  },
  created() { },
  wacth: {},
  methods: {
    // 获取职位详情
    getdetail(e) {
      this.api.recruitDetail({id:e}).then((res) => {
        // console.log(res);
        this.list = res.data
      })
    },
  },
  computed: {},
};
</script>
<style lang="scss" scoped>
.zhiwei {
  background: #fafafa;
  overflow: hidden;
  width: 100vw;
  .main {
    width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-bottom: 30px;
    img {
      margin-top: 30px;
      margin-bottom: 40px;
    }
    .job {
      margin-left: 70px;
      margin-right: 58px;
      .top {
        border-bottom: 1px solid #eeeeee;
      }
      div {
        margin-bottom: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #981c1c;
      }
    }
  }
}
</style>
